import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { SectionType } from '@sales-libs/shared/data-access/sc-generated';
import { ScSharedActions, ScSharedSelectors } from '@sales-libs/shared/feature';
import {
  deformatUrlFriendlyName,
  formatUrlFriendlyName,
  removeSpecialChars,
} from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class ConfigurationGuideResolver {
  userGuid: string;
  productLine: string;
  guideId: number;
  defaultLanguage = 'en';
  selectedLanguage: string;

  constructor(private store: Store) {}

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<any> {
    this.selectedLanguage = state.url.split('/').slice(1, 2).join('/');
    this.store.dispatch(
      ScSharedActions.getUserGuides({ language: this.selectedLanguage }),
    );

    this.userGuid = route.params?.userGuid;
    this.productLine = route.params?.productLine;

    this.store
      .pipe(select(ScSharedSelectors.userGuides), filterTruthy(), take(1))
      .subscribe((userGuides) => {
        this.guideId =
          userGuides.find(
            (userGuide) =>
              removeSpecialChars(userGuide.name?.toLowerCase()) ===
              deformatUrlFriendlyName(this.productLine)?.toLowerCase(),
          )?.id ?? 0;

        this.store.dispatch(
          ScSharedActions.getGuide({
            guideId: this.guideId,
            language: this.selectedLanguage,
            sectionType: SectionType.Configuration,
            guid: this.userGuid,
          }),
        );

        this.store
          .pipe(select(ScSharedSelectors.guide), filterTruthy(), take(1))
          .subscribe((guide) => {
            const currentSection = guide?.sections?.find(
              (section) =>
                formatUrlFriendlyName(section.name) === route.params?.stepName,
            );

            this.store.dispatch(
              ScSharedActions.SetScSharedState({
                productLineName: this.productLine,
                userGuid: this.userGuid,
                currentSection: currentSection,
                selectedLanguageCode: this.selectedLanguage,
              }),
            );
          });
      });

    return this.store.pipe(
      select(ScSharedSelectors.guide),
      filterTruthy(),
      take(1),
    );
  }
}
