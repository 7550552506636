import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { environment } from '../../environments/environment';

export const imports = [
  CommonModule,
  TranslateModule,
  DsSpacingModule,
  FlexLayoutModule,
  MatButtonModule,
];

@Component({
  selector: 'tks-page-not-found',
  standalone: true,
  imports,
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageNotFoundComponent {
  homeAddress = environment.LINKS.TKS;
}
