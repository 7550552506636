import { Routes } from '@angular/router';
import {
  SlScGeneralOnboardingGuard,
  SlScOnboardingGuard,
} from '@sales-libs/sc-onboarding/feature';
import { environment } from '../environments/environment';
import { MaintenancePageComponent } from './maintenance-page/maintenance-page.component';
import { GeneralOnboardingComponent } from './onboarding/general/general-onboarding.component';
import { OnboardingComponent } from './onboarding/onboarding.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ConfigurationGuideResolver } from './shared/resolver/configuration-guide.resolver';
import { SummaryGuideResolver } from './shared/resolver/summary-guide.resolver';
import { SummaryComponent } from './summary/summary.component';

export const appRoutes: Routes = [
  { path: '', redirectTo: '/en', pathMatch: 'full' },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: 'special/pagenotfound',
    component: PageNotFoundComponent,
  },
  {
    path: 'maintenance',
    component: MaintenancePageComponent,
  },
  {
    path: ':language',
    component: GeneralOnboardingComponent,
    data: { environment: environment },
    canActivate: [SlScGeneralOnboardingGuard],
  },
  {
    path: 'share/:configurationCode',
    loadChildren: () =>
      import('./summary/summary.module').then((m) => m.SummaryModule),
    component: SummaryComponent,
    resolve: {
      guide: SummaryGuideResolver,
    },
  },
  {
    path: ':language/:productLine',
    component: OnboardingComponent,
    canActivate: [SlScOnboardingGuard],
  },
  {
    path: ':language/:productLine/maintenance',
    component: MaintenancePageComponent,
  },
  {
    path: ':language/:productLine/:userGuid/questionnaire/:stepName',
    data: { environment: environment },
    loadChildren: () =>
      import('./questionnaire/questionnaire.module').then(
        (m) => m.QuestionnaireModule,
      ),
  },
  {
    path: ':language/:productLine/:userGuid/configuration/:stepName',
    loadChildren: () =>
      import('./configuration/configuration.module').then(
        (m) => m.ConfigurationModule,
      ),
    resolve: {
      guide: ConfigurationGuideResolver,
    },
  },
  {
    path: '**',
    redirectTo: '/special',
  },
];
