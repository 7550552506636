<div class="full-height" fxLayout="column" fxLayoutAlign="space-between">
  <sl-sc-onboarding
    [enableGeneralOnboardingScreen]="isGeneralOnboardingScreenEnabled"
    (languageSelectionChange)="onLanguageSelectionChange($event)"
  ></sl-sc-onboarding>
  <sl-shared-sc-footer
    *ngIf="selectedLanguage"
    [language]="selectedLanguage.code"
  ></sl-shared-sc-footer>
</div>
