import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { select, Store } from '@ngrx/store';
import {
  SlScSummaryActions,
  SlScSummarySelectors,
} from '@sales-libs/sc-summary/feature';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs/operators';

@Injectable()
export class SummaryGuideResolver {
  defaultLanguage = 'en';

  constructor(private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): any {
    if (route.params?.configurationCode) {
      this.store.dispatch(
        SlScSummaryActions.getSharedUserGuide({
          configurationCode: route.params?.configurationCode,
        }),
      );

      return this.store.pipe(
        select(SlScSummarySelectors.userGuide),
        filterTruthy(),
        take(1),
      );
    }
  }
}
