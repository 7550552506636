import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatCardModule } from '@angular/material/card';

export const imports = [
  CommonModule,
  TranslateModule,
  DsSpacingModule,
  FlexLayoutModule,
  MatCardModule,
];

@Component({
  selector: 'tks-maintenance-page',
  standalone: true,
  imports,
  templateUrl: './maintenance-page.component.html',
  styleUrl: './maintenance-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MaintenancePageComponent {}
