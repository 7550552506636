<div
  fxLayout="column"
  [fxLayoutGap]="1 | dsSpacing"
  fxLayoutAlign="center center"
  class="page-not-found-wrap"
>
  <img
    src="assets/svg/icons/no-search-result.svg"
    alt="no search result icon"
  />

  <h2 class="no-margin-top">
    {{ 'turnkey_solution.page_not_found.headline' | translate }}
  </h2>

  <p>
    {{ 'turnkey_solution.page_not_found.something_went_wrong' | translate }}
    <br />
    {{ 'turnkey_solution.page_not_found.requested_url_not_found' | translate }}
  </p>

  <a mat-button mat-stroked-button [href]="homeAddress">
    {{ 'turnkey_solution.page_not_found.go_home' | translate }}
  </a>
</div>
