<div class="maintenance-wrap" fxLayoutAlign.xs="center">
  <a
    href="https://www.palfinger.com"
    target="_blank"
    rel="noopener"
    class="maintenance-logo"
  >
    <img
      src="/assets/img/logo.svg"
      alt="PALFINGER logo"
      height="auto"
      width="250"
    />
  </a>

  <mat-card class="maintenance-card" data-test="maintenance-card">
    <mat-card-header>
      <mat-card-title>
        {{ 'turnkey_solution.maintenance.headline' | translate }}
      </mat-card-title>
    </mat-card-header>
    <mat-card-content>
      <p>
        {{ 'turnkey_solution.maintenance.website_inaccessible' | translate }}
      </p>
      <br />
      <p>
        {{ 'turnkey_solution.maintenance.patience_appreciated' | translate }}
      </p>
    </mat-card-content>
  </mat-card>
</div>
